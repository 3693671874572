<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" md="10" lg="9">
        <h2 class="font-weight-light text-uppercase">
          <v-badge color="primary" :content="angebote.length" :value="angebote.length > 0">
            Angebote für dich
          </v-badge>
        </h2>
      </v-col>
      <v-col cols="12" md="10" lg="9">
        <v-row justify="center">
          <v-col cols="12" v-if="angebote.length == 0 && alternativen.length == 0" align-self="center" class="mt-5 text-center">
            <h2 class="text-center">
              Es gibt derzeit leider keine Sportangebote in deiner Altersklasse.
            </h2>
            <v-btn class="mt-5" rounded color="primary" to="/user/entdecken">Andere Sportangebote entdecken</v-btn>
          </v-col>
          <v-col cols="12" v-if="angebote.length == 0 && alternativen.length > 0" align-self="center" class="mt-5 text-center">
            <h2 class="text-center">
              Es gibt derzeit leider keine Angebote für deine Sportarten.
            </h2>
            <v-btn class="mt-5" rounded color="primary" to="/user/entdecken">Andere Sportarten entdecken</v-btn>
          </v-col>
          <v-col
            cols="12"
            lg="6"
            v-for="(item, index) in angebote"
            :key="index"
          >
            <sportangebotcard :item="item"></sportangebotcard>
          </v-col>
          <v-col cols="12" v-if="angebote.length <= 3 && alternativen.length > 0">
            <v-row justify="center">
              <v-col cols="12" class="my-3">
                <v-divider></v-divider>
              </v-col>
              <v-col cols="12">
                <h2 class="text-center font-weight-light">
                  Wie wäre es mit {{ alternativen.length > 1 ? 'diesen Sportangeboten' : 'diesem Sportangebot' }}?
                </h2>
              </v-col>
              <v-col
                cols="12"
                lg="6"
                v-for="(item, index) in alternativen"
                :key="index"
              >
                <sportangebotcard :item="item"></sportangebotcard>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import card from '@/components/Sportangebot/card.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'Sportangebote',
  data() {
    return {
      selected_offer: { active: false },
    }
  },
  components: {
    'sportangebotcard': card,
  },
  computed: {
    ...mapGetters({
      user: 'user',
      vereine: 'vereine',
      db: 'db',
      sportarten: 'sportarten',
      sportangebote: 'sportangebote',
    }),
    angebote(){
      var datum = new Date()
      var today = datum.getFullYear()+'-'+(datum.getMonth()+1).toString().padStart(2, '0')+'-'+datum.getDate().toString().padStart(2, '0')
      return this.sportangebote.all.filter(item => 
          this.user.data.sportarten.includes(item.sportart?.name) 
          && item.active == true && item.altersgruppen.includes(this.user.data.alter.toString())
          && ((item.wiederholung == 'Einmaliger Termin' && item.termin.datum >= today) || (item.wiederholung == 'Regelmäßige Termine' && item.zeitraum_termine.filter(t => t.datum >= today).length > 0) || (item.wiederholung == 'Mehrere Termine' && item.termine.filter(t => t.datum >= today)))
          ) || []
    },
    alternativen(){
      var datum = new Date()
      var today = datum.getFullYear()+'-'+(datum.getMonth()+1).toString().padStart(2, '0')+'-'+datum.getDate().toString().padStart(2, '0')
      return this.sportangebote.all.filter(item => 
          item.active == true && item.altersgruppen.includes(this.user.data.alter.toString())
          && ((item.wiederholung == 'Einmaliger Termin' && item.termin.datum >= today) || (item.wiederholung == 'Regelmäßige Termine' && item.zeitraum_termine.filter(t => t.datum >= today).length > 0) || (item.wiederholung == 'Mehrere Termine' && item.termine.filter(t => t.datum >= today)))
          ) || []
    }
  },
  methods: {
    verein(id) {
      return this.vereine.all.find((verein) => verein.id == id)
    },
    sportart(name) {
      return this.sportarten.all.find((art) => art.name == name)
    },
    parseDateTime(obj) {
      var datum = obj.datum.split('-')
      var uhrzeit = obj.uhrzeit.split(':')
      return (
        datum[2] +
        '.' +
        datum[1] +
        '.' +
        datum[0] +
        ' um ' +
        uhrzeit[0] +
        ':' +
        uhrzeit[1] +
        ' Uhr'
      )
    },
    parseTime(obj) {
      var uhrzeit = obj.uhrzeit.split(':')
      return uhrzeit[0] + ':' + uhrzeit[1] + ' Uhr'
    },
    parseDate(obj) {
      var datum = obj.datum.split('-')
      return datum[2] + '.' + datum[1] + '.' + datum[0]
    },
  },
}
</script>
